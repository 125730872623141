export const environment = {
  production: true,
  endpoint: 'https://api.meu.fan',
  simulateMobile: false,
  debug: true,
  verion: '1.0.0',
  google: {
    webClientId: '939950798273-l6p5keb77ecbh91i72i22lca617hsj97.apps.googleusercontent.com',
    iOSClientId: '939950798273-3n7polstnhe96a09p9svej1obbua0dqc.apps.googleusercontent.com',
    iOSServerClientId: ''
  },
  apple: {
    webClientId: '939950798273-l6p5keb77ecbh91i72i22lca617hsj97.apps.googleusercontent.com',
    iOSClientId: '',
    iOSServerClientId: ''
  },
  onesignal_id: 'c2c6cc5d-1dcc-481e-9f45-4ddc80836c02'
};
