import { Injectable } from '@angular/core';
import { Toast } from '@capacitor/toast';
import { LoadingController } from '@ionic/angular/standalone';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Browser } from '@capacitor/browser';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private isDesktop = new BehaviorSubject<boolean>(false);
  private readonly THEME_KEY = 'app-theme';

  constructor(public loadingController: LoadingController, private storageService: StorageService) { }


  async loading(message: string) {
    return await this.loadingController.create();
  }


  async showToast(message: string) {
    await Toast.show({
      text: message,
      duration: 'long',
      position: 'center'
    });
  }

  formatSlug(text: string): string {
    return text
      .toLowerCase()
      .normalize("NFD") // Normaliza a string removendo os acentos
      .replace(/[\u0300-\u036f]/g, "") // Remove todos os caracteres acentuados
      .replace(/[^a-z0-9 -]/g, "") // Remove caracteres especiais
      .replace(/\s+/g, '-') // Substitui espaços por hifens
      .replace(/-+/g, '-'); // Remove múltiplos hifens se houver
  }

  onResize(size: number) {
    if (size < 568) {
      this.isDesktop.next(false);
    } else {
      this.isDesktop.next(true);
    }
  }

  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }

  async openUrl(url: string) {
    await Browser.open({ url: url });
  }


  async consolelog(param1: any, param2: any = null) {
    if (environment.debug) {
      console.log(param1, param2);
    }
  }

  async setTheme(isDarkMode: boolean) {
    const theme = isDarkMode ? 'dark' : 'light';
    document.documentElement.classList.toggle('ion-palette-dark', isDarkMode);
    await this.storageService.set(this.THEME_KEY, theme);
  }

  async loadTheme() {
    // const savedTheme = await this.storageService.get(this.THEME_KEY);
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // const isDark = savedTheme ? savedTheme === 'dark' : prefersDark.matches;
    // document.documentElement.classList.toggle('ion-palette-dark', isDark);
  }

  async checkAndRequestReview() {
    // Obter contagem de sessões e se o review já foi solicitado
    const sessionCount = (await this.storageService.get('session_count')) || 0;
    const reviewRequested = (await this.storageService.get('review_requested')) || false;

    // Incrementar sessão se o app foi aberto
    if (!reviewRequested) {
      await this.storageService.set('session_count', sessionCount + 1);
    }

    // Retornar se o review já foi solicitado ou se não estamos na quarta sessão
    if (reviewRequested || sessionCount < 4) {
      return false;
    }

    const isNativePlatform = Capacitor.isNativePlatform();
    if (!isNativePlatform) {
      this.consolelog('Ambiente não é nativo, review não solicitado.');
      return false;
    }

    // Solicitar review e registrar que foi solicitado
    try {

      const { InAppReview } = await import('@capacitor-community/in-app-review');
      await InAppReview.requestReview();
      await this.storageService.set('review_requested', true);
      this.consolelog('Solicitação de avaliação exibida com sucesso.');
      return true;
    } catch (error) {
      console.error('Erro ao solicitar avaliação:', error);
      return false;
    }
  }
}
