import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public TOKEN_KEY: string = 'meufantoken';
  endpoint: string = 'http://api.meufan.test:8500/v1';
  platformType: string = 'Web';

  constructor(
    public http: HttpClient,
    public platform: Platform,
    public storage: StorageService,
    private util: UtilService
  ) {
    this.endpoint = environment.endpoint;
    this.determinePlatform();
  }

  determinePlatform() {
    if (this.platform.is('android')) {
      this.platformType = 'Android';
    } else if (this.platform.is('ios')) {
      this.platformType = 'iOS';
    }
    // Não precisa adicionar uma condição específica para 'web' porque já definimos o padrão como 'Web'
  }

  get(url: string) {
    return new Promise((resolve, reject) => {

      this.storage.get(this.TOKEN_KEY).then(token => {
        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token)
            .append('X-Platform', this.platformType)
        };

        this.http.get(this.endpoint + url, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response | HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.process401Request(url, null, err)
          }
          reject(err);
        });
      });
    });
  }

  post(url: string, data: any) {
    return new Promise((resolve, reject) => {
      this.storage.get(this.TOKEN_KEY).then(token => {
        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token)
            .append('X-Platform', this.platformType),
        };

        this.http.post(this.endpoint + url, data, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response | HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.process401Request(url, data, err)
          }
          reject(err);
        });
      });
    });
  }

  upload(url: string, data: any) {
    return new Promise((resolve, reject) => {
      this.storage.get(this.TOKEN_KEY).then(token => {

        let headers = {
          headers: new HttpHeaders()
            .append('Accept', 'application/json')
            .append('enctype', 'multipart/form-data')
            .append('Authorization', 'Bearer ' + token)
            .append('X-Platform', this.platformType),
        };

        this.http.post(this.endpoint + url, data, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response | HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.process401Request(url, data, err)
          }
          reject(err);
        });
      });
    });
  }

  put(url: string, data: any) {
    return new Promise((resolve, reject) => {

      this.storage.get(this.TOKEN_KEY).then(token => {

        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token)
            .append('X-Platform', this.platformType),
        };

        this.http.put(this.endpoint + url, data, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response | HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.process401Request(url, data, err)
          }
          reject(err);
        });


      });
    });
  }

  delete(url: string) {
    return new Promise((resolve, reject) => {

      this.storage.get(this.TOKEN_KEY).then(token => {

        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token)
            .append('X-Platform', this.platformType),
        };

        this.http.delete(this.endpoint + url, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response | HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.process401Request(url, null, err)
          }
          reject(err);
        });


      });
    });
  }


  process401Request(url: string, data: any, erro: any) {
    this.util.consolelog(url, JSON.stringify(erro));
  }
}
